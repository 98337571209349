import { Col, Layout, message, Row, Select, SelectProps } from "antd";
import axios from "axios";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { hasElems, hasText } from "../../utils/ts_helpers";

interface SearchInputProps<T> extends Omit<SelectProps, 'value'> {
    value?: T;
    onValueChange: (value: T) => void;
    valueProperty?: keyof T;
    labelFormatter?: (value: T) => String;
    label?: string;
    menuHeader?: ReactNode;
    fetchDataRequest: (keyword: string, signal: AbortSignal, isSoftDeleted?: boolean) => Promise<T[]>;
    filter?: (r: T) => boolean;
    render: (value: T) => ReactElement;
    initData?: T[];
};

function SearchInput<T>({ value, onValueChange, valueProperty, labelFormatter, label, menuHeader, fetchDataRequest, render, filter, initData, ...rest }: SearchInputProps<T>): JSX.Element {

    const [list, setList] = useState<T[]>(initData ?? []);

    const [input, setInput] = useState(label ?? '');

    const { Option } = Select; 

    useEffect(() => {

        const controller = new AbortController();

        const fetchData = async (key: string) => {
            try {
                const result = await fetchDataRequest(key, controller.signal, true);
                if (filter) {
                    setList(result.filter(filter));;
                } else {
                    setList(result);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn('Abfrage ist abgebrochen worden.');
                }
                else {
                    message.error(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
                }
            }
        }

        // TODO: rebuild backend functions to get empty list, if query keyword is empty string
        if (hasText(input)) {
            fetchData(input);
        }

        return () => controller.abort();
    }, [input]);

    useEffect(() => {
        setInput(label ?? '');
    }, [label]);

    return (
        <Select
            value={labelFormatter && value ? labelFormatter(value): valueProperty && value ? value[valueProperty] : undefined}
            filterOption={(input, option) => {
                return input.split(" ").some(s => option?.label?.toString()?.toLowerCase().includes(s.toLowerCase())) || false
            }}
            showSearch
            searchValue={input}
            optionLabelProp="label"
            dropdownAlign={{ overflow: { shiftX: 0 } }}
            dropdownRender={(menu) =>
                hasElems(list) ?
                    <Row align="middle" justify="center">
                        <Col span={24}>
                            {menuHeader}
                        </Col>
                        <Col span={24}>
                            {menu}
                        </Col>
                    </Row> :
                    <Layout>{menu}</Layout>
            }
            onSearch={(value) => setInput(value)}
            onSelect={value => {
                if (valueProperty !== undefined) {
                    const newValue = list.find(v => v[valueProperty] === value); if (newValue) { onValueChange(newValue); }
                } else {
                    onValueChange(value);
                }
            }}
            onChange={value => {
                if (valueProperty !== undefined) {
                    const newValue = list.find(v => v[valueProperty] === value); if (newValue) { onValueChange(newValue); }
                } else {
                    onValueChange(value);
                }
            }}
            {...rest}
        >
            {list.map(
                d => <Option value={valueProperty ? d[valueProperty] : d + ''} label={labelFormatter ? labelFormatter(d) : valueProperty && value ? value[valueProperty] : undefined}>{render(d)}</Option>
            )}
        </Select>
    )
}

export default SearchInput;