import { FileDoneOutlined, FileTextOutlined, SmileOutlined, ToolOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import TokenService from "../../services/TokenService";
export default function Sidebar() {

    const auth = TokenService.getAuth();

    const location = useLocation();

    const { Sider } = Layout;

    const items: MenuProps['items'] = !auth?.roles?.find(role => 'ROLE_ADMIN'.includes(role)) ? [
        {
            key: '/customers',
            label: <NavLink to="/customers">
                <SmileOutlined /> Person
            </NavLink>,
        },
        {
            key: '/offers',
            label: <NavLink to="/offers">
                <FileDoneOutlined /> Angebot
            </NavLink>,
        },] :
        [{
            key: '/customers',
            label: <NavLink to="/customers">
                <SmileOutlined /> Person
            </NavLink>,
        },
        {
            key: '/offers',
            label: <NavLink to="/offers">
                <FileDoneOutlined /> Angebot
            </NavLink>,
        },
        {
            key: '/history',
            label: <NavLink to="/history">
                <FileTextOutlined /> History
            </NavLink>,
        },
        {
            key: '/merge',
            label: <NavLink to="/merge">
                <ToolOutlined /> Mergetool
            </NavLink>,
        },];

    return (
        <Sider style={{ top: 20, height: '100%', backgroundColor: 'white' }}>
            <Menu selectedKeys={[location.pathname]} items={items} />
            {/* TODO: temporarily disabled, enable again after the new dashboard finished. */}
            {/* <Menu.Item key={'/start'}>
                    <NavLink to="/start">
                    <HomeOutlined />
                    Start
                    </NavLink>
                </Menu.Item> */}
        </Sider >
    )
}
