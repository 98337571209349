import { AutoComplete, AutoCompleteProps, Empty, message, Row, Spin } from 'antd';
import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { hasText, noElems } from '../../utils/ts_helpers';

interface SearchAutoCompleteProps<T> extends Omit<AutoCompleteProps, 'value'> {
    value?: string;
    onValueChange: (value: string) => void;
    property: keyof T;
    handleSelect: (value: T) => void;
    menuHeader?: ReactNode;
    fetchDateRequest: (keyword: string, signal: AbortSignal, isSoftDeleted: boolean) => Promise<T[]>;
    render: (value: T) => { value?: number | string, label: ReactNode };
};

function SearchAutoComplete<T>({ value, onValueChange, property, handleSelect, menuHeader, fetchDateRequest, render, ...rest }: SearchAutoCompleteProps<T>): ReactNode {

    const [openAutoCompleteDropdown, setOpenAutoCompleteDropdown] = useState(false);

    const [isSearching, setIsSearching] = useState(false);

    const [list, setList] = useState<T[]>([]);

    const [input, setInput] = useState('');

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                setOpenAutoCompleteDropdown(true);
                setIsSearching(true);
                const result = await fetchDateRequest(input, controller.signal, true);
                setList(result);
                if (noElems(result)) {
                    setOpenAutoCompleteDropdown(false);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn('Abfrage ist abgebrochen worden.');
                }
                else {
                    message.error(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
                }
            } finally {
                setIsSearching(false);
            }
        }


        // TODO: rebuild backend functions to get empty list, if query keyword is empty string
        if (hasText(input)) {
            fetchData();
        }

        return () => controller.abort();
    }, [input]);

    return (
        <AutoComplete
            onSearch={(value) => setInput(value)}
            onSelect={(value) => {
                const newValue = list.find(l => l[property] === value);
                if (newValue !== undefined) {
                    handleSelect(newValue);
                }
                setOpenAutoCompleteDropdown(false);
            }}
            onChange={(value: string) => {
                onValueChange(value);
            }}
            open={openAutoCompleteDropdown}
            value={value}
            onInputKeyDown={e => {
                if (e.code === 'Escape') {
                    e.preventDefault();
                    setOpenAutoCompleteDropdown(false);
                }
                if (e.code === 'Enter') {
                    e.preventDefault();
                    setOpenAutoCompleteDropdown(false);
                }
            }}
            onBlur={() => { setOpenAutoCompleteDropdown(false); }}
            options={[{
                title: '',
                label: (isSearching ? <Row justify='center'><Spin size='large'></Spin></Row> :
                    menuHeader
                ),
                options:
                    isSearching || noElems(list) ? [] :
                        [...list.map(render)]
            }]}
            notFoundContent={<Empty></Empty>}
            dropdownAlign={{ overflow: { shiftX: 0 } }}
            {...rest}
        >
        </AutoComplete>
    )
}

export default SearchAutoComplete