import { CarTwoTone, FireTwoTone } from "@ant-design/icons"
import { Kunde } from "../../services/api-types"

interface MandantIconProps {
    customer: Kunde
}

export const MandantIcon = ({customer}: MandantIconProps) => {
    if (customer.smartDifferent) return <FireTwoTone title='Energie' twoToneColor='red' />
    if (customer.smartInnovation) return <CarTwoTone title='Leasing' />
    return ''
}
