import { Bindung } from './api-types';
import STIClient from "./STIClient";

const Bindung_REST_BASE_URL = '/bindung';

class BindungService {
    async getAllBindungen(): Promise<Bindung[]> {
        const res = await STIClient.get(Bindung_REST_BASE_URL, {
            method: 'GET',
        });
        return res.data;
    }

    async createNewBindung(bindung: Bindung): Promise<Bindung> {
        const res = await STIClient.post(Bindung_REST_BASE_URL + '/new', { "id": bindung });
        return res.data;
    }

    async getBindungById(id: number): Promise<Bindung> {
        const res = await STIClient.get(Bindung_REST_BASE_URL + '/' + id);
        return res.data;
    }

    async updateBindung(oldBindung: Bindung, newBindung: Bindung): Promise<Bindung> {
        const res = await STIClient.put(Bindung_REST_BASE_URL, { "oldbindung": { "id": oldBindung }, "newbindung": { "id": newBindung } });
        return res.data;
    }

    async deleteBindung(bindung: Bindung) {
        return await STIClient.delete(Bindung_REST_BASE_URL, { data: { "id": bindung } });
    }
}

export type { Bindung };

const bindungService = new BindungService();

export default bindungService;