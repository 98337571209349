import { Button, Col, Image, Layout, Row, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AuthencationService from "../../services/AuthencationService";
import TokenService from "../../services/TokenService";
import { handleBeforeunloadEvent } from '../../utils/ts_helpers';

export default function Topbar() {

    const auth = TokenService.getAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const logout = () => {
        window.removeEventListener('beforeunload', handleBeforeunloadEvent);
        AuthencationService.logout();
        navigate('/login', { state: { from: location }, replace: true });
    }

    const { Header } = Layout;

    return (
        <Header style={{ backgroundColor: 'rgba(255,255,255,1)', padding: 0 }}>
            <Row gutter={[8, 8]}>
                <Col span={2}>
                    <Image src="/images/Logo.png" alt=""></Image>
                </Col>
                {
                    auth ?
                        <>
                            <Col span={3} offset={19}>
                                <Tag color="#f00">{auth.username}</Tag>
                                <Button onClick={() => { logout(); }}>Abmelden</Button>
                            </Col>
                        </>
                        :
                        <></>
                }
            </Row>
        </Header>
    )
}