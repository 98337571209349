import { CarTwoTone, FireTwoTone, UserAddOutlined } from "@ant-design/icons";
import { Affix, Button, Col, Input, Layout, Row, Spin, TablePaginationConfig, message } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useEffect, useState } from "react";
import { ConfirmDelete } from "../../components/Customer/ConfirmDelete";
import SearchAutoComplete from "../../components/Form/SearchAutoComplete";
import Sidebar from "../../components/Sidebar/Sidebar";
import SearchTable, { ColumnTypeStringKey } from "../../components/Table/SearchTable";
import Topbar from "../../components/Topbar/Topbar";
import KundenService from '../../services/KundenService';
import LookUpService from '../../services/LookUpService';
import { KundenView, LookupLists, Sorter, lookup } from "../../services/api-types";
import { errorMessage, handleBeforeunloadEvent, handleErrorResponse, isPresent } from '../../utils/ts_helpers';
import { MandantIcon } from "../../components/Customer/MandantIcon";

export default function CustomerList() {

  window.removeEventListener('beforeunload', handleBeforeunloadEvent);

  dayjs.locale('de');

  const { Content } = Layout;

  ////////////////////////////////////////////////////////////////////////
  // component state
  ////////////////////////////////////////////////////////////////////////

  // data state
  const [data, setData] = useState<KundenView[]>([]);
  const [dataRow, setDataRow] = useState<KundenView>();
  const [lookup, setLookup] = useState(LookupLists.DEFAULT());
  const [representColumns, setRepresentColumns] = useState(['Kunde von', 'Name', 'Vorname', 'Zusatzbezeichnung', 'Adresse', 'Vermittler']);

  // state of current filter
  const [params, setParams] = useState<{ [key: string]: string }>({});
  const onParamsChange = (key: string, value: string | undefined) => {
    setParams(prev => {
      let newParams = Object.assign({}, prev);
      if (isPresent(value)) {
        newParams[key] = value;
      } else {
        delete newParams[key];
      }
      return newParams;
    });
  };

  // loading state
  const [isLookUpLoading, setIsLookUpLoading] = useState(true);

  // view state
  const [open, setOpen] = useState(false);

  ////////////////////////////////////////////////////////////////////////
  // effects and handlers
  ////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    LookUpService.getLookUp().then(response => {
      setLookup(response);
    }).catch(error => {
      errorMessage(error);
    }).finally(() => {
      setIsLookUpLoading(false);
    });
  }, []);

  const loadData = (params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal) => {
    return KundenService.globalSearch(params, columns, pagination, sorters, signal);
  }

  const handleConfirmDelete = async (customer?: KundenView) => {
    try {
      if (customer?.kundeId?.toString().match(/[0-9]+/)) {
        await KundenService.deleteKunde(customer.kundeId);
        message.success('Der Kunde wurd erfolgreich gelösht.');
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getConfirmDeleteMessage = (customer?: KundenView): string => {
    return customer ? 'Möchten Sie der Kunde ' + [customer?.vorname, customer?.name].filter(isPresent).join(', ') + ' wirklich löschen?' : '';
  }

  const handleAlertClose = () => {
    setOpen(false);
  };

  const formatVermittler = (vermittler?: lookup.Vermittler) =>
    vermittler ?
      <>{vermittler.name} <MandantIcon customer={vermittler} /></>
      :
      <></>
    ;

  ////////////////////////////////////////////////////////////////////////
  // column definitions
  ////////////////////////////////////////////////////////////////////////

  const columns: ColumnTypeStringKey<KundenView>[] = [
    {
      title: 'Kunde von',
      key: 'Kunde von',
      children: [{
        dataIndex: 'kundeVon',
        key: 'kundeVon',
        title:
          <Input
            placeholder="Filter Kunde"
            value={params['kundeVon']}
            onChange={e => {
              onParamsChange('kundeVon', e.target.value)
            }}
          />,
      }],
    },
    {
      title: 'Name',
      key: 'Name',
      sorter: { multiple: 2 },
      dataIndex: 'name',
      children: [{
        dataIndex: 'name',
        key: 'name',
        title:
          <Input placeholder='Filter Name'
            value={params['name']}
            onChange={e => {
              onParamsChange('name', e.target.value);
            }}
          />,
      }],
    },
    {
      title: 'Vorname',
      dataIndex: 'vorname',
      sorter: { multiple: 1 },
      key: 'Vorname',
      children: [{
        dataIndex: 'vorname',
        key: 'vorname',
        title:
          <Input placeholder='Filter Vorname'
            value={params['vorname']}
            onChange={e => {
              onParamsChange('vorname', e.target.value);
            }}
          />,
      }],
    },
    {
      title: 'Zusatzbezeichnung',
      key: 'Zusatzbezeichnung',
      dataIndex: 'zusatzbezeichnung',
      sorter: { multiple: 4 },
      children: [{
        dataIndex: 'zusatzbezeichnung',
        key: 'zusatzbezeichnung',
        title: <Input placeholder='Filter Zusatzbezeichnung'
          value={params['zusatzbezeichnung']}
          onChange={e => { onParamsChange('zusatzbezeichnung', e.target.value) }}
        />,
      }],
    },
    {
      title: 'Rechtsform',
      key: 'Rechtsform',
      dataIndex: 'rechtsform',
      children: [{
        dataIndex: 'rechtsform',
        key: 'rechtsform',
        title: <Input
          placeholder='Filter Rechtsform'
          value={params['rechtsform']}
          onChange={e => onParamsChange('rechtsform', e.target.value)} />,
      }],
    },
    {
      title: 'Branche',
      key: 'Branche',
      children: [{
        dataIndex: 'branche',
        key: 'branche',
        title: <Input
          placeholder='Filter Branche'
          value={params['branche']}
          onChange={e => onParamsChange('branche', e.target.value)} />,
      }],
    },
    {
      title: 'Post/Rechnungsadresse',
      key: 'Adresse',
      dataIndex: 'adresse',
      sorter: { multiple: 3 },
      children: [{
        dataIndex: 'adresse',
        key: 'adresse',
        title:
          <Input
            placeholder='Filter Adresse'
            value={params['adresse']}
            onChange={e => onParamsChange('adresse', e.target.value)}>
          </Input>,
      }],
    },
    {
      title: 'Kontaktvermittler',
      key: 'Vermittler',
      children: [{
        dataIndex: 'vermittler',
        key: 'vermittler',
        title: <SearchAutoComplete<lookup.Vermittler>
          value={params['vermittler']}
          onValueChange={(value: string) => {
            onParamsChange('vermittler', value);
          }}
          property={"name"}
          handleSelect={(value: lookup.Vermittler) => {
            onParamsChange('vermittler', value.name);
          }}
          popupMatchSelectWidth={1000}
          style={{ width: '100%' }}
          fetchDateRequest={LookUpService.getVermittlerListByName}
          render={(value: lookup.Vermittler) => ({ value: value.name, label: formatVermittler(value) })}
        >
        </SearchAutoComplete>,
      }],
    },
    {
      title: 'Untervermittler',
      key: 'Untervermittler',
      children: [{
        dataIndex: 'vermittler',
        key: 'untervermittler',
        title: <SearchAutoComplete<lookup.Vermittler>
          value={params['untervermittler']}
          onValueChange={(value: string) => {
            onParamsChange('untervermittler', value);
          }}
          property={"name"}
          handleSelect={(value: lookup.Vermittler) => {
            onParamsChange('untervermittler', value.name);
          }}
          popupMatchSelectWidth={1000}
          style={{ width: '100%' }}
          fetchDateRequest={LookUpService.getVermittlerListByName}
          render={(value: lookup.Vermittler) => ({ value: value.name, label: formatVermittler(value) })}
        >
        </SearchAutoComplete>,
      }],
    },
    {
      title: 'Agentur',
      key: 'Agentur',
      children: [{
        dataIndex: 'agentur',
        key: 'agentur',
        title: <Input
          placeholder='Filter Agentur'
          value={params['agentur']}
          onChange={e => onParamsChange('agentur', e.target.value)} />,
      }],
    },
    {
      title: 'Kontakt',
      key: 'Kontakt',
      children: [{
        dataIndex: 'kontakt',
        key: 'kontakt',
        render: (value: KundenView['kontakt']) => value?.split(',').map(k => <div>{k}</div>)
      }]
    },
    {
      title: 'Geburtsdatum / Gründungsdatum',
      key: 'Geburtsdatum',
      sorter: { multiple: 5 },
      children: [{
        dataIndex: 'geburtsdatum',
        key: 'geburtsdatum',
        title: <Input
          placeholder='Filter Geburtsdatum/Gründungsdatum'
          value={params['geburtsdatum']}
          onChange={e => onParamsChange('geburtsdatum', e.target.value)} />,
        render: (value => value ? dayjs(value).format('DD.MM.YYYY') : ''),
      }]
    },
    {
      title: 'BLZ',
      key: 'BLZ',
      sorter: { multiple: 6 },
      children: [{
        dataIndex: 'blz',
        key: 'blz',
        title: <Input
          placeholder='Filter BLZ'
          value={params['blz']}
          onChange={e => onParamsChange('blz', e.target.value)} />,
      }]
    },
    {
      title: 'BIC',
      key: 'BIC',
      sorter: { multiple: 6 },
      children: [{
        dataIndex: 'bicswift',
        key: 'bicswift',
        title: <Input
          placeholder='Filter BIC'
          value={params['bic']}
          onChange={e => onParamsChange('bic', e.target.value)} />,
      }]
    }
  ];


  ////////////////////////////////////////////////////////////////////////
  // render component
  ////////////////////////////////////////////////////////////////////////

  return (
    isLookUpLoading ?
      <Row style={{ padding: 50 }} align="middle" justify="center">
        <Spin size="large" />
      </Row>
      : // not loading
      <Layout>
        <Topbar />
        <Layout style={{
          backgroundColor: '#ffffff',
        }}>
          <Affix>
            <Sidebar />
          </Affix>
          <Layout>
            <Content style={{
              backgroundColor: '#ffffff',
              padding: 20,
              boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
              margin: 20,
            }}>
              <ConfirmDelete
                message={"Wollen Sie wirklich den Kunden  '" + dataRow?.name + "' Löschen?"}
                onCancel={handleAlertClose}
                onOk={() => handleConfirmDelete(dataRow)}
                visible={open} />
              <Row gutter={[8, 8]}>
                <Col span={18}>
                  Neuen Kunden anlegen: <Button type="primary" ghost href={"/#/customer"} ><UserAddOutlined /></Button>
                </Col>
                <Col span={24}>
                  <SearchTable<KundenView>
                    bordered
                    columns={columns}
                    globalSearch
                    globalSearchPlaceHolder="Filter Kunden"
                    params={params}
                    onParamsChange={onParamsChange}
                    resetParams={() => setParams(() => ({}))}
                    data={data}
                    setData={setData}
                    request={loadData}
                    representColumns={representColumns}
                    setRepresentColumns={setRepresentColumns}
                    confirmDelete={handleConfirmDelete}
                    message={getConfirmDeleteMessage}
                    handleEditRecord={(customer: KundenView) => window.open('/#/customer/' + customer.kundeId, '_self')}
                    hideDelete={false}
                  ></SearchTable>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </Layout>
  )
}
