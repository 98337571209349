import { UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { Kunde, LookupLists, lookup } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { checkShowSpouseButton, createNameOfGmeinsamerPerson, updateBindungsarten } from '../../utils/ts_helpers';
import { BindungId } from '../../utils/ui-ids';


interface EditBindungenProps {
    lookup: LookupLists
    mainPerson: Kunde
    associatedPerson: Kunde
    bindungen: { [index: string]: number[] }
    onBindungenChange: (b: { [index: string]: number[] }) => void
    deletedBindungen: { [index: string]: number[] }
    onDeletedBindungenChange: (b: { [index: string]: number[] }) => void
    enabled?: boolean
    isProposal: boolean
    showSpouseButton?: boolean
    setShowSpouseButton: (p: boolean) => void
    createGemeinsamerPerson: (p1: Kunde, p2: Kunde) => void
}


export function EditBindungen({
    lookup,
    mainPerson,
    associatedPerson,
    bindungen,
    onBindungenChange,
    deletedBindungen,
    onDeletedBindungenChange,
    enabled = true,
    isProposal,
    showSpouseButton,
    setShowSpouseButton,
    createGemeinsamerPerson
}: EditBindungenProps) {
    function updateCurrentBindungen(forwardBindungsId: BindungId.Type, forwardBindungsArtIds: number[], backwardBindungsId: BindungId.Type): void {

        let backwardBindungsArtIds = bindungen[backwardBindungsId] || [];

        for (let id of forwardBindungsArtIds) {
            const reverseBaId = findReversedBindungsArt(id);
            if (reverseBaId !== undefined) {
                backwardBindungsArtIds.push(reverseBaId)
            }
        }

        const newCurrentBindungen = {
            ...bindungen,
            [forwardBindungsId]: removeDuplicates(forwardBindungsArtIds),
            [backwardBindungsId]: removeDuplicates(backwardBindungsArtIds)
        }

        setShowSpouseButton(checkShowSpouseButton(mainPerson, associatedPerson, newCurrentBindungen));
        onBindungenChange(newCurrentBindungen);
    }

    const findReversedBindungsArt: (id: number) => number | undefined = id =>
        getBindungsArt(id)?.rueckbindungsart

    const removeDuplicates: <T>(arr: Array<T>) => Array<T> = arr => arr.filter((item, pos) => arr.indexOf(item) === pos)

    const getBindungsArt: (id: number) => lookup.Bindungsart | undefined = (id: number) =>
        lookup.bindungsartList.find(ba => ba.id === id);

    const calcBindungsOptionList = (person1: Kunde, person2: Kunde, lookup: LookupLists) => {
        const selectedBindungsarten = bindungen[BindungId.fromKunden(person1, person2)]
        const isEmpty = !selectedBindungsarten || selectedBindungsarten.length === 0;
        const hasExclusive = selectedBindungsarten && selectedBindungsarten.some(id => lookup.bindungsartList.filter(ba => ba.id === id).some(ba => ba.exklusiv))

        return updateBindungsarten(person1, person2, lookup, bindungen).map<OptionProps>(ba => {
            return {
                key: ba.id,
                value: ba.id,
                disabled: !(isEmpty || (!hasExclusive && !ba.exklusiv) || (hasExclusive && selectedBindungsarten.at(0) === ba.id)),
                children: [],
                label: ba.bindungsart
            }
        })
    };

    const fwd_is_valid = !enabled || (bindungen[BindungId.fromKunden(mainPerson, associatedPerson)] || []).length > 0
    const bwd_is_valid = !enabled || (bindungen[BindungId.fromKunden(associatedPerson, mainPerson)] || []).length > 0

    return <><Row gutter={[16, 8]}>
        <Col span={12}>
            <Form.Item
                required={enabled}
                label={''
                    + fmt.kunde(associatedPerson)
                    + ' ist ... von '
                    + fmt.kunde(mainPerson)
                }>
                <Select
                    status={bwd_is_valid ? '' : 'error'}
                    disabled={!enabled}
                    key={BindungId.fromKunden(associatedPerson, mainPerson)}
                    mode='multiple'
                    value={bindungen[BindungId.fromKunden(associatedPerson, mainPerson)]}
                    onChange={value => {
                        updateCurrentBindungen(BindungId.fromKunden(associatedPerson, mainPerson), value, BindungId.fromKunden(mainPerson, associatedPerson));
                    }}
                    onSelect={(value: number) => {
                        let newDeletedBindungen = Object.assign({}, deletedBindungen)
                        if (deletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)]) {
                            newDeletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)] = deletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)].filter(bindungsArtId => bindungsArtId !== value)
                            onDeletedBindungenChange(newDeletedBindungen)
                        }
                    }}
                    onDeselect={(value: number) => {
                        if (associatedPerson.id && mainPerson.id) {
                            let newDeletedBindungen = Object.assign({}, deletedBindungen)
                            if (newDeletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)]) {
                                newDeletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)].push(value)
                            } else {
                                newDeletedBindungen[BindungId.fromKunden(associatedPerson, mainPerson)] = [value]
                            }
                            onDeletedBindungenChange(newDeletedBindungen)
                        }
                    }}
                    filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
                    options={calcBindungsOptionList(associatedPerson, mainPerson, lookup)}
                >
                </Select>
                {!bwd_is_valid ? <Typography.Text type="danger">Bitte mindestens eine Bindungsart auswählen!</Typography.Text> : undefined}
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item
                required={enabled} label={''
                    + fmt.kunde(mainPerson)
                    + ' ist ... von '
                    + fmt.kunde(associatedPerson)
                }
            >
                <Select
                    status={fwd_is_valid ? '' : 'error'}
                    disabled={!enabled}
                    key={BindungId.fromKunden(mainPerson, associatedPerson)}
                    mode='multiple'
                    value={bindungen[BindungId.fromKunden(mainPerson, associatedPerson)]}
                    onChange={value => {
                        updateCurrentBindungen(BindungId.fromKunden(mainPerson, associatedPerson), value, BindungId.fromKunden(associatedPerson, mainPerson))
                    }}
                    onSelect={(value: number) => {
                        let newDeletedBindungen = Object.assign({}, deletedBindungen)
                        if (deletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson)]) {
                            newDeletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson,)] = deletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson)].filter(bindungsArtId => bindungsArtId !== value)
                            onDeletedBindungenChange(newDeletedBindungen)
                        }
                    }}
                    onDeselect={(value: number) => {
                        if (associatedPerson.id && mainPerson.id) {
                            let newDeletedBindungen = Object.assign({}, deletedBindungen)
                            if (newDeletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson,)]) {
                                newDeletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson,)].push(value)
                            } else {
                                newDeletedBindungen[BindungId.fromKunden(mainPerson, associatedPerson,)] = [value]
                            }
                            onDeletedBindungenChange(newDeletedBindungen)
                        }
                    }}
                    filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
                    options={calcBindungsOptionList(mainPerson, associatedPerson, lookup)}
                >
                </Select>
                {!fwd_is_valid ? <Typography.Text type="danger">Bitte mindestens eine Bindungsart auswählen!</Typography.Text> : undefined}
            </Form.Item>
        </Col>
    </Row>
        <Row hidden={showSpouseButton || isProposal}>
            <Col>
                Gemeinsame Person Anlegen: <Button title="Anlegen"
                    onClick={() => {
                        Modal.confirm({
                            title: "Anlegen bestätigen?",
                            cancelText: "Abbrechen",
                            okText: "Anlegen",
                            onOk: () => {
                                createGemeinsamerPerson(mainPerson, associatedPerson);
                                setShowSpouseButton(true);
                            },
                            content: `Möchten Sie die gemeinsame Person '${createNameOfGmeinsamerPerson(mainPerson, associatedPerson)}' anlegen?`,
                            closable: true,
                        })
                    }}
                    style={{ color: 'steelblue' }}
                    icon={<UsergroupAddOutlined />}></Button>
            </Col>
        </Row>
    </>

}
